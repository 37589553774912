import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './Styles/index.css';
import reportWebVitals from './reportWebVitals.js';
import { AuthProvider } from './AuthContext.js';
const App = lazy(() => import('./App.js'));

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();