import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// Votre configuration Firebase
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY, // Utilisez des variables d'environnement
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: "tombola-65bd0",
  storageBucket: "tombola-65bd0.appspot.com",
  messagingSenderId: "388808625987",
  appId: "1:388808625987:web:75e82643e8f48bb105e9f6"
};


// Initialisation de Firebase avec la configuration
const app = initializeApp(firebaseConfig);

// Instances pour l'authentification et Firestore
const auth = getAuth(app);
const db = getFirestore(app);
const providerFacebook = new FacebookAuthProvider();
const providerGoogle = new GoogleAuthProvider();
providerGoogle.setCustomParameters({
  prompt: 'select_account'
});

export { app, auth, db, providerGoogle, providerFacebook };
