import React, { createContext, useState, useEffect, useCallback } from 'react';
import { getAuth, onAuthStateChanged, signOut, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup } from "firebase/auth";
import './firebaseConfig.js'

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        setIsLoggedIn(true);
        setUser(authUser);
      } else {
        setIsLoggedIn(false);
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const login = useCallback(async (provider) => {
    setError('');
    try {
      const result = await signInWithPopup(auth, provider);
      setIsLoggedIn(true);
      setUser(result.user);
    } catch (error) {
      setError(`Erreur de connexion avec ${provider.providerId} : ${error.message}`);
    }
  }, [auth]);

  const loginWithGoogle = () => login(new GoogleAuthProvider());
  const loginWithFacebook = () => login(new FacebookAuthProvider());

  const logout = async () => {
    setError('');
    try {
      await signOut(auth);
      setIsLoggedIn(false);
      setUser(null);
    } catch (error) {
      setError(`Erreur lors de la déconnexion : ${error.message}`);
    }
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, user, loginWithGoogle, loginWithFacebook, logout, error }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
